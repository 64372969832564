import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { inject, observer } from 'mobx-react'
// import {TransitionPortal} from 'gatsby-plugin-transition-link'
import EventListener from 'react-event-listener'
import { TimelineMax, TweenMax } from 'gsap'

import css from "@css/components/header.module.styl"
import SvgLogo from "@images/global__lbl__logo.svg"
import TransLink from '@components/MyTransitionLink'
import MenuList from '@components/MenuList'
// import { Link } from 'gatsby'

@inject('store')
@observer
class Header extends React.Component{
    constructor(props) {
        super(props)
        this.logoRef = React.createRef()
    }
    componentDidMount(){
        this.handleResize()
    }

    handleResize = () => {
        const { store } = this.props
        const { UserAgentStore } = store
        if( window.innerWidth < 768 ){
            UserAgentStore.isMobile = true
        } else {
            UserAgentStore.isMobile = false
        }
    }
    render(){
        const { store, location } = this.props
        const { UserAgentStore } = store
        if(this.logoRef.current){
            if( UserAgentStore.isModal ){
                TweenMax.to(this.logoRef.current, 0.6,{
                    opacity: 0
                })
            } else {
                TweenMax.to(this.logoRef.current, 0.6,{
                    opacity: 1
                })
            }
        }
        return (
            <header className={css.header}>
                <EventListener target="window" onResize={this.handleResize} />
                <div className={css.container}>
                    <nav className={css.navi}>
                        <ul className={css.list_left}>
                            <li className={`${css.item} ${css.logo}`} ref={this.logoRef}>
                                <Link to='/' direction='back'>
                                    <SvgLogo/>
                                </Link>
                            </li>
                        </ul>
                        { UserAgentStore.isMobile
                            ? null
                            :
                            <MenuList css={css} location={location}/>
                        }
                    </nav>
                </div>
            </header>
        )
    }
}
// Header.propTypes = {
//  siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//  siteTitle: ``,
// }

export default Header
