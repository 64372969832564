import React from 'react'
import { Link } from 'gatsby'
import TransLink from '@components/MyTransitionLink'

const MyLink = ({to, children, location, css,trigger, delay}) => {
    let className = css.item
    let current = location.pathname.match(to)
    return(
        <li className={`${css.item} ${current ? css.itemCurrent : ''}`} key={to}>
            <Link to={to} delay={delay} onClick={trigger}>{children}</Link>
        </li>
    )
}

const MenuList = ({css, location, trigger, delay}) => (
    <ul className={css.menuList}>
        <MyLink to='/about' location={location} css={css} delay={delay} trigger={trigger}>
            ABOUT
        </MyLink>
        {/*<MyLink to='/recruit' location={location} css={css} delay={delay} trigger={trigger}>
            RECRUIT
        </MyLink>*/}
        <MyLink to='/news' location={location} css={css} delay={delay} trigger={trigger}>
            NEWS
        </MyLink>
        <MyLink to='/contact' location={location} css={css} delay={delay} trigger={trigger}>
            CONTACT
        </MyLink>
    </ul>
)

export default MenuList
