import React from "react"
import { inject, observer } from 'mobx-react'
import { motion, AnimatePresence } from 'framer-motion'
// import { Link } from "gatsby"

import '@css/style.styl'
import css from '@css/components/transition.module.styl'

import Header from '@components/Header'
import ModalMenu from '@components/ModalMenu'
import SvgLogo from "@images/global__lbl__logo-w.svg"

const duration = 0.6
const delay = 1.0
const ease = { ease: [0.75, 0.0, 0.25, 1.00]}

const mainVars = {
    initial: {
        position: 'relative',
        opacity: 1,
    },
    enter: {
        transition: {
            duration: duration,
            delay: delay,
            when: 'beforeChildren',
        },
        opacity: 1,
    },
    exit: {
        transition: { duration: duration },
        opacity: 1,
    },
}

const coverVars = (isRoot) => {
    return {
        initial: {
            zIndex: 9999,
            background: `#00A8F8`,
            position: 'fixed',
            width: `100%`,
            height: `100%`,
            top: 0,
            left: 0,
            x: isRoot ? '100%' : '0%',
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'afterChildren',
                ...ease,
            },
            x: `100%`,
        },
        exit: {
            transition: {
                duration: duration,
                // tims: [duration/2,duration/2],
                ...ease,
            },
            x: [`-100%`,`0%`]
        },
    }
}


@inject('store')
@observer
class TransitionLayout extends React.PureComponent {
    next = null
    pre = null

    componentDidMount(){
        const { location, children } = this.props
        // console.log(location.pathname)
        this.coverRef = React.createRef()
    }

    componentDidUpdate(){
        const { location } = this.props
        this.pre = location.pathname

    }
	render() {
		const { location, children, store } = this.props
        const { UserAgentStore } = store
		const rootPath = `${__PATH_PREFIX__}/`
        this.next = location.pathname
        let isRoot = false
        let isNextRoot = false
        // if( location.pathname == "/"){
        //     isRoot = true
        // }
        console.log('header')
		return (
			<div>
                <AnimatePresence
                    onExitComplete={()=>{}}
                    exitBeforeEnter
                >
                    <motion.div
                        key={location.pathname}
                        variants={mainVars}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        { UserAgentStore.isMobile && <ModalMenu location={location}/>}
                        <Header location={location}/>
                        {children}
                    </motion.div>
                </AnimatePresence>
				<AnimatePresence
                    onExitComplete={()=>{}}
                    exitBeforeEnter
                >
                    <motion.div
                        key={`${location.pathname}-cover1`}
                        variants={coverVars(isRoot)}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        <div className={css.container}>
                            <div className={css.block}>
                                <SvgLogo className={css.logo}/>
                            </div>
                        </div>
                    </motion.div>
				</AnimatePresence>
			</div>
		)
	}
}

export default TransitionLayout
