import React from "react"
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link'
import { TimelineMax } from 'gsap'
import { customEase } from 'src/utils'

import css from '@css/components/transition.module.styl'

// import LogoSvg from "@images/global__lbl__logo-w.svg"

export default class MyTransitionLink extends React.Component{
	constructor(props){
		super(props)
		this.coverRef = React.createRef()
		// this.logoRef = React.createRef()
		this.customEase = customEase()
	}

	animation = ({ props:{ length: seconds }, direction, node, delay }) => {
		const wait = (seconds - delay) / 6
		const half = (seconds - wait - delay) / 2;
		const ease = this.customEase

		let p0 = 100
		let p1 = 0
		let p2 = -100

		// if( direction === "back" ){
		// 	p0 = -100
		// 	p2 = 100
		// }


		return new TimelineMax()
		    .set(this.coverRef.current, { x: `${p0}%` })
		    .to(this.coverRef.current, wait, {
		    	delay: delay,
		    	x: `${p1}%`,
		        ease: ease
		    })
		    .set(node, { opacity: 0 })
		    .set(this.coverRef.current, {
		    	delay: half,
		    	opacity: 0
		    })
		    // .to(
		    // 	this.coverRef,
		    //     half,
		    //     {
		    //     	x: `${p2}%`,
		    //       	ease: ease
		    //     },
		    //     `+=${wait}`
		    // );
	}

	render(){
		const {...props} = this.props
		const length = this.props.duration || 1.5
		const direction = this.props.direction || 'up'
		let delay = this.props.delay ? this.props.delay : 0
		// console.log(length / 2 + delay)
		return(
			<>
				<TransitionLink
				    exit={{
				    	length: length + delay,
				    	trigger: ({ exit, node }) => {
				    		this.animation({ props: exit, direction, node, delay})
				    	}
				    }}
				    entry={{
				    	delay: length / 2 + delay
				    }}
				    {...props}
				>
					{ this.props.children }
				</TransitionLink>
				<TransitionPortal>
					<div className={css.container}
			            ref={this.coverRef}
			            style={{
			            	position: "fixed",
			              	// background: this.props.bg || "#00A7FC",
			              	top: 0,
			              	right: 0,
			              	width: "100vw",
			              	height: "100vh",
			              	// opacity: 0.3,
			              	transform: "translateX(100%)",
			            }}
			        >
			        	<div className={css.block}>
				         	{/*<div className={css.logo} ref={this.logoRef}>
				         	    <LogoSvg/>
				         	</div>*/}
			         	</div>
			        </div>
				</TransitionPortal>
			</>
		)
	}
}
