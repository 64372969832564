import CustomEase from 'assets/js/CustomEase.js'

export let getScrollTop = () => {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
    )
}

export let customEase = () => {
    return CustomEase.create("custom", "M0,0 C0.356,-0.002 0.298,0.608 0.4,0.8 0.506,1 0.764,1 1,1")
}

export let random = (max,min) => {
    return Math.floor( Math.random() * (max + 1 - min) ) + min
}