/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


import wrapWithProvider from './wrap-with-provider'
import wrapWithLayout from './wrap-with-layout'

//mobx
export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithLayout

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (typeof window.IntersectionObserver === `undefined`) {
//         import(`intersection-observer`)
//         console.log(`# IntersectionObserver is polyfilled!`)
//     }
// }

//transition Layout

const transitionDelay = 1000

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    //#用
    if( location.hash !== "" ){
        return false
    }

    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
};