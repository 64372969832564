import React from 'react'
import { inject, observer } from 'mobx-react'
import { TimelineMax, TweenMax } from 'gsap'

import css from "@css/components/ModalMenu.module.styl"
import MenuList from '@components/MenuList'
import MenuSVG from '@images/global__btn__menu.svg'

import { customEase } from 'src/utils'

@inject('store')
@observer
class ModalMenu extends React.PureComponent {

	menuTl = null
	listTls = []
	scrollPosition = 0
	constructor(props) {
		super(props)
		this.menuButtonRef = React.createRef()
		this.menuRef = React.createRef()
		this.overlayRef = React.createRef()
		this.customEase = customEase()
	}
	// componentDidMount(){
	// }
	handleMenuBottom = () => {
		const { store, location } = this.props
		const { UserAgentStore } = store
		let ease = this.customEase
		let spd = 0.2

		if( !this.menuTl1 || !this.menuTl2){
			let svg = this.menuButtonRef.current.childNodes[0].childNodes[0]
			let top = svg.childNodes[0]
			let bottom = svg.childNodes[1]
		    this.menuTl1 = new TimelineMax()
	        .pause()
	        .add('open')

	        .to(bottom, spd, {
	        	delay: spd/2,
	            scaleX: 0,
	            transformOrigin: '100% 50%',
	            ease: ease,
	            rotation: 0,
                fill: '#000'
	        })
	        .to(bottom, spd, {
	        	rotation: -45,
	        	y: `${-16.5}px`,
	        	x: `${-4.5}px`,
	        	transformOrigin: '100% 50%',
                fill: '#fff'
	        })
	        .to(bottom, spd, {
	            rotation: -45,
	            y: `${-16.5}px`,
	            x: `${-4.5}px`,
	            scaleX: 1,
	            transformOrigin: '100% 50%',
	            ease: ease,
	        })
	        .add('close')

    	    this.menuTl2 = new TimelineMax()
            .pause()
            .add('open')
            .to(top, spd, {
                // y: `${5}px`,
                // x: `${-4.5}px`,
                transformOrigin: '100% 50%',
                scaleX: 0,
                ease: ease,
                rotation: 0,
                fill: '#000'
            })
            .to(top, spd, {
            	delay: spd/2,
            	rotation: 45,
            	y: `${16.5}px`,
            	x: `${-4.5}px`,
            	transformOrigin: '100% 50%',
                fill: '#fff'
            })
            .to(top, spd, {
                rotation: 45,
                y: `${16.5}px`,
                x: `${-4.5}px`,
                scaleX: 1,
                transformOrigin: '100% 50%',
                ease: ease,
            })
            .add('close')

            //メニューアニメーション
            let menu = this.menuRef.current
            this.menuTl3 = new TimelineMax()
            .pause()
            .add('open')
            .set(menu,{
            	display: 'none',
            })
            // .to(menu, 0.5, {
            // 	opacity: 0,
            // 	ease: ease,
            // })
            .to(menu, 1.0, {
            	display: 'flex'
            })
            .add('close')

            //overlay
            let overlay = this.overlayRef.current
            this.menuTl4 = new TimelineMax()
            .pause()
            .add('open')
            .set(overlay,{
            	display: 'none',
            })
            .to(overlay, 0.3, {
            	opacity: 0,
            	ease: ease,
            })
            .to(overlay, 0.3, {
            	display: 'flex',
            	opacity: 1,
            	ease: ease,
            })
            .add('close')

	    }

	    let menu = this.menuRef.current
	    let nodes = menu.childNodes[0].childNodes

		if(UserAgentStore.isModal){
			UserAgentStore.isModal = false
			this.menuTl1.tweenTo('open')
			this.menuTl2.tweenTo('open')
			this.menuTl3.tweenTo('open')
			this.menuTl4.tweenTo('open')
			this.menuListHideAnimation(nodes)
			// document.body.classList.remove("noscroll")
			// document.body.style.top = 0
			// window.scrollTo( 0 , this.scrollPosition )
		} else {
			UserAgentStore.isModal = true
			this.menuTl1.tweenTo('close')
			this.menuTl2.tweenTo('close')
			this.menuTl3.tweenTo('close')
			this.menuTl4.tweenTo('close')
			this.menuListShowAnimation(nodes)
			// this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
			// document.body.classList.add("noscroll")
			// document.body.style.top = `-${this.scrollPosition}px`
		}
		return true
	}

	menuListHideAnimation = (nodes) => {
		let ease = this.customEase

        for(let i = 0; i < nodes.length; i++){
        	let node = nodes[i]
        	let tl = new TimelineMax()
    		tl.to(node, 0.3, {
            	delay: i * 0.15 - i * 0.1,
            	opacity: 0,
            	x: `10%`,
            	ease: ease
            })
        }
	}

	menuListShowAnimation = (nodes) => {
		let ease = this.customEase

        for(let i = 0; i < nodes.length; i++){
        	let node = nodes[i]
        	let tl = new TimelineMax()
    		tl.set(node,{
    			display: 'block',
    	    	opacity: 0,
    	    	x: `10%`
    	    })
        	.to(node, 0.3, {
            	delay: 0.3 + i * 0.15 - i * 0.1,
            	opacity: 1,
            	x: `0%`,
            	ease: ease
            })
        }
	}

	render(){
		const { store, location } = this.props
		const { UserAgentStore } = store
        let isRoot = false
        if( location.pathname == "/"){
            isRoot = true
        }
		return(
			<div className={css.container}>
				<div ref={this.overlayRef} className={`${ isRoot ? css.overlayNone : css.overlay}`} style={{display: 'none'}}/>
				<div ref={this.menuButtonRef} className={css.menuButton} onTouchEnd={this.handleMenuBottom}>
					<MenuSVG/>
				</div>
				<nav ref={this.menuRef} className={css.wrap} style={{display: 'none'}}>
					<MenuList css={css} location={location} delay={0.8} trigger={this.handleMenuBottom}/>
				</nav>
			</div>
		)
	}
}

export default ModalMenu