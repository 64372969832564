import {observable, action} from 'mobx'

class FullPageStore {
    @observable currentSection = 0
    direction = null

    @action
    increment() {
        this.IndexScrollY += 1
    }

    @action
    decrement() {
      this.IndexScrollY -= 1
    }
}

export default new FullPageStore()